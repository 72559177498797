import { Injectable } from '@angular/core';
import { AppConfigService } from '../../app-config.service';
import { interval, Observable } from 'rxjs';

export interface WorkingTime {
  isClosingTime: boolean;
  isRemindingTime: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class WorkingTimeService {
  private readonly idVStartTime: number;
  private readonly idVEndTime: number;
  private readonly idVMinutesToRemind: number;

  constructor(private configService: AppConfigService) {
    this.idVStartTime = configService.config['idVStartHour'];
    this.idVEndTime = configService.config['idVEndHour'];
    this.idVMinutesToRemind = configService.config['idVMinutesToRemind'];
  }

  private get currentHours(): number {
    return new Date().getHours();
  }

  private get currentMinutes(): number {
    return new Date().getMinutes();
  }

  public get startTime(): string {
    return this.idVStartTime + ':00';
  }

  public get endTime(): string {
    return this.idVEndTime + ':00';
  }

  public get minutesToClose(): number {
    return this.idVMinutesToRemind;
  }

  public get isWorkingTimeObservable(): Observable<WorkingTime> {
    return new Observable(subscriber => {
      interval(1000).subscribe(() => {
        subscriber.next({
          isClosingTime: !this.isWorkingTime,
          isRemindingTime: this.isRemindingTime,
        });
      });
    });
  }

  public get isWorkingTime(): boolean {
    return this.currentHours >= this.idVStartTime && this.currentHours < this.idVEndTime;
  }

  public get isRemindingTime(): boolean {
    const isLastWorkingHour = () => {
      return this.currentHours === this.idVEndTime - 1;
    };

    const isInRemindingMinutes = () => {
      return this.currentMinutes >= 60 - this.idVMinutesToRemind;
    };

    return isLastWorkingHour() && isInRemindingMinutes();
  }
}
